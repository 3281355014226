import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Separator = (props) => {
  const { as: Component, context, utility, primary, className, ...rest } = props;
  const textClasses = classnames(
    'particles-navigation-separator',
    className,
    {
      'particles-navigation-separator--context': !!context,
      'particles-navigation-separator--utility': !!utility,
      'particles-navigation-separator--primary': !!primary
    }
  );
  return (
    <Component className={textClasses} {...rest} />
  );
};

Separator.defaultProps = {
  as: 'span'
};
Separator.propTypes = {
  /** Enable utility styling */
  utility: PropTypes.bool,
  /** Enable primary styling */
  primary: PropTypes.bool,
  /** Enable context styling */
  context: PropTypes.bool,
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Separator.displayName = 'Navigation.Separator';

export default Separator;

export const {
  defaultProps,
  propTypes,
  displayName
} = Separator;
