import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Text = (props) => {
  const { as: Component, context, className, ...rest } = props;
  const textClasses = classnames(
    'particles-navigation-text',
    className,
    {
      'particles-navigation-text--context': !!context
    }
  );
  return (
    <Component className={textClasses} {...rest} />
  );
};

Text.defaultProps = {
  as: 'span'
};
Text.propTypes = {
  /** Enable context styling */
  context: PropTypes.bool,
  /** Child components */
  children: PropTypes.node,
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Text.displayName = 'Navigation.Text';

export default Text;

export const {
  defaultProps,
  propTypes,
  displayName
} = Text;
