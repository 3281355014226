import React from 'react';
import PropTypes from 'prop-types';
import Input from './input.js';
import _ from 'lodash';

export const Tile = ({ as: Component, children, className, ...rest }) => {
  const inputPropsWhitelist = ['invalid', 'value', 'checked', 'defaultChecked', 'multi', 'disabled', 'onChange', 'name'];
  const inputProps = (props) => _.pick(props, inputPropsWhitelist);
  const componentProps = (props) => _.omit(props, inputPropsWhitelist);
  return (
    <label className={`hxChoice particles-choice-tile__wrapper ${className ? className : ''}`}>
      <Input {...inputProps(rest)} />
      <Component className="particles-choice-tile_tile hxTile" {...componentProps(rest)}>
        <i className="hxIcon hx--type--checkmark fa fa-check" />
        {children}
      </Component>
    </label>
  );
};
Tile.defaultProps = { onChange: _.noop, as: 'div' };
Tile.displayName = 'ChoiceTiles.Tile';
Tile.propTypes = {
  /** Render as this kind of component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string,
  /** Marks as invalid */
  invalid: PropTypes.bool,
  /** Marks as disabled */
  disabled: PropTypes.bool,
  /** Marks as checked (makes it a fully controlled component as a side effect) */
  checked: PropTypes.bool,
  /** Marks as checked by default (without making it a fully controlled component) */
  defaultChecked: PropTypes.bool,
  /** Input event handler */
  onChange: PropTypes.func,
  /** Allows multi-selection */
  multi: PropTypes.bool,
  /** Determines the value of the input */
  value: PropTypes.string,
  /** Determines the name of the input */
  name: PropTypes.string
};

export default Tile;
