import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Header from './Header/index.js';
import Footer from './Footer/index.js';
import Collection from '../Collection/index.js';
import Body from './Body/index.js';
import { useMaturityLevel } from '../../component-maturity.js';

const wrap = (children, Wrapper, ...OtherWrappers) => (
  (children && ([Wrapper, ...OtherWrappers].indexOf(children.type) < 0)) ? (
    <Wrapper>{children}</Wrapper>
  ) : children
);

/** Card is a simple container for information. Alone it does very little more than just adding a border around your content. */
const Card = (props) => {
  useMaturityLevel('stable', 'Card');
  const { className, children, header, footer, inGrid, centered, ...rest } = props;
  const classes = classnames('particles-card', className, {
    'particles-card--in-grid': !!inGrid,
    'particles-card--centered': !!centered
  });
  if (!children && !header && !footer) {
    return null;
  }
  return (
    <div className={classes} {...rest}>
      {wrap(header, Header)}
      {wrap(children, Body)}
      {wrap(footer, Footer, Collection)}
    </div>
  );
};

Card.propTypes = {
  /** Content to show at the top of the card */
  header: PropTypes.node,
  /** Content to show at the bottom of the card */
  footer: PropTypes.node,
  /** When wrapping with a `Grid` component, set this property to true to get proper styling */
  inGrid: PropTypes.bool,
  /** Center content in the middle of the card */
  centered: PropTypes.bool,
  /** Additional class to add to component */
  className: PropTypes.string,
  /** Child components*/
  children: PropTypes.node
};

Card.defaultProps = {
  className: '',
  inGrid: false,
  centered: false
};

Card.displayName = 'Card';
Card.Body = Body;
Card.Header = Header;
Card.Footer = Footer;

export default Card;
export { Body, Header, Footer };

export const {
  propTypes,
  defaultProps,
  displayName
} = Card;
