import React from 'react';
import PropTypes from 'prop-types';
import Container from '../Page/PageContainer/index.js';
import _ from 'lodash';
import { useMaturityLevel } from '../../component-maturity.js';

const AlertBar = (props) => {
  useMaturityLevel('feedback', 'AlertBar');
  const { title, level, className, children, noIcon, ...attrs } = props;
  const baseClasses = (className) => _.compact([
    'particles-alertbar',
    className,
    `particles-alertbar--${level}`
  ]).join(' ');
  const contentClasses = (noIcon, level) => _.compact([
    'particles-alertbar__content',
    `particles-alertbar__content--${level}`,
    !noIcon && 'particles-alertbar__content--with-icon',
    !noIcon && `particles-alertbar__content--${level}-with-icon`
  ]).join(' ');
  return (
    <div {...attrs} className={baseClasses(className)}>
      <Container>
        <div className={contentClasses(noIcon, level)}>
          {title && (<span className="particles-alertbar__title">{title}: </span>)}{children}
        </div>
      </Container>
    </div>
  );
};

AlertBar.propTypes = {
  noIcon: PropTypes.bool,
  level: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

AlertBar.defaultProps = {
  noIcon: false,
  level: 'info'
};

AlertBar.displayName = 'AlertBar';

export default AlertBar;

export const {
  propTypes,
  defaultProps,
  displayName
} = AlertBar;
