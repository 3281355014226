import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Error from './Error/index.js';
import Actions from './Actions/index.js';
import Action from './Action/index.js';
import _ from 'lodash';
import { MaturityLevel } from '../../component-maturity.js';

class Form extends React.Component {
  getChildContext = () => ({
    formState: _.pick(this.props, ['valid', 'dirty', 'submitting'])
  });

  render = () => {
    const { props } = this;
    const {
      className,
      enableHtmlValidation,
      children,
      name,
      onChange,
      onSubmit,
      size
    } = props;
    const formClasses= classnames(
      'particles-form',
      `particles-form--${size}`,
      className);
    const error = props.error && (
      <Error>{props.error}</Error>
    );
    return (
      <form
        name={name}
        onChange={onChange}
        onSubmit={onSubmit}
        noValidate={!enableHtmlValidation}
        className={formClasses}
      >
        {error}
        {children}
        <MaturityLevel level="stable" component="Form" />
      </form>
    );
  };
};

Form.propTypes = {
  /** Handler for the change event */
  onChange: PropTypes.func,
  /** Handler for the submit event */
  onSubmit: PropTypes.func,
  /** Adjust the styling of form to different sizes */
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large', 'huge', 'unbound']),
  /** `name` attribute value */
  name: PropTypes.string,
  /** Child components */
  children: PropTypes.node,
  /** Extra classes */
  className: PropTypes.string,
  /** Validate on the client side using HTML5 validators */
  enableHtmlValidation: PropTypes.bool,
  /** Message to show when form has an error */
  error: PropTypes.string
};

Form.defaultProps = {
  size: 'medium',
  name: 'form',
  enableHtmlValidation: false
};

Form.childContextTypes = {
  formState: PropTypes.shape({
    dirty: PropTypes.bool,
    valid: PropTypes.bool,
    submitting: PropTypes.bool
  })
};

Form.Error = Error;
Form.Actions = Actions;
Form.Action = Action;
Form.displayName = 'Form';

export default Form;
export { Error, Actions, Action };

export const {
  propTypes,
  defaultProps,
  displayName
} = Form;
