import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Collection from '../../Collection/index.js';

class Actions extends React.Component {
  getChildContext = () => ({ formState: this.computeFormState() });

  render = () => {
    const { props } = this;
    const {
      className,
      children,
      as,
      ...rest
    } = props;
    const formState = this.computeFormState();
    const actionsClasses = classnames(
      'particles-form__actions',
      className,
      {
        'particles-form__actions--dirty': !!formState.dirty,
        'particles-form__actions--valid': !!formState.valid,
        'particles-form__actions--submitting': !!formState.submitting
      }
    );
    const extraProps = as ? rest : {};
    const Component = as || Collection;
    return (
      <Component className={actionsClasses} {...extraProps}>
        {children}
      </Component>
    );
  };

  computeFormState = () => {
    const { valid, dirty, submitting } = this.props;
    const formState = this.context.formState || {};
    return {
      valid: valid !== undefined ? valid : formState.valid,
      dirty: dirty !== undefined ? dirty : formState.dirty,
      submitting: submitting !== undefined ? submitting : formState.submitting
    };
  }
}

Actions.contextTypes = {
  formState: PropTypes.object
};
Actions.childContextTypes = {
  formState: PropTypes.object
};
Actions.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** Child components */
  children: PropTypes.node,
  /** Whether form has been validated or not */
  valid: PropTypes.bool,
  /** Whether form has been changed or not */
  dirty: PropTypes.bool,
  /** Form is being submitted */
  submitting: PropTypes.bool,
  /** String or function to transform the component into, ie: Button, 'a', Link, etc... */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};

Actions.displayName = 'Actions';

export default Actions;

export const {
  propTypes,
  displayName
} = Actions;
