import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Menu from './Menu/index.js';
import Context from './Context/index.js';
import Link from './Link/index.js';
import Text from './Text/index.js';
import Separator from './Separator/index.js';
import Logo from './Logo/index.js';
import Utility from './Utility/index.js';
import Primary from './Primary/index.js';
import Bar from './Bar/index.js';
import Dropdown from './Dropdown/index.js';
import { useMaturityLevel } from '../../component-maturity.js';

const Navigation = (props) => {
  useMaturityLevel('stable', 'Navigation');
  const { className, as: Component, footer, ...rest } = props;
  const navigationClasses = classnames('particles-navigation', { 'particles-navigation--footer': footer }, className);
  return (<Component className={navigationClasses} {...rest} />);
};

Navigation.defaultProps = {
  as: 'div'
};
Navigation.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div', 'section', 'nav' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};

Navigation.Menu = Menu;
Navigation.Link = Link;
Navigation.Text = Text;
Navigation.Separator = Separator;
Navigation.Logo = Logo;
Navigation.Bar = Bar;
Navigation.Context = Context;
Navigation.Utility = Utility;
Navigation.Primary = Primary;
Navigation.Dropdown = Dropdown;

Navigation.displayName = 'Navigation';

export default Navigation;

export { Menu, Link, Text, Separator, Logo, Bar, Context, Utility, Primary, Dropdown };

export const {
  defaultProps,
  propTypes,
  displayName
} = Navigation;
