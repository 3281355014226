import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useMaturityLevel } from '../../component-maturity.js';

const colors = ['green', 'gray', 'red', 'yellow', 'pink', 'none'];
const sizes = ['tiny', 'small', 'medium', 'large', 'huge', 'unbound', 'auto'];

const classes = ({ color, filled, size } = {}) => classnames('particles-pill', {
  [`particles-pill--${color}`]: (colors.indexOf(color) >= 0),
  [`particles-pill--${color}-filled`]: !!filled,
  [`particles-pill--${size}`]: (sizes.indexOf(size) >= 0)
});

const Pill = ({ color, size, filled, children } = {}) => {
  useMaturityLevel('stable', 'Pill');
  return children ? (
    <span className={classes({ color, filled, size })}>{children}</span>
  ) : null;
};

Pill.displayName = 'Pill';
Pill.propTypes = {
  /** Color for the pill: red, green, yellow, pink, gray, none */
  color: PropTypes.oneOf(colors),
  /** Fill the pill background - defaults to false */
  filled: PropTypes.bool,
  /** Size for the pill: tiny, small, medium, large, huge, unbound, auto */
  size: PropTypes.oneOf(sizes)
};
Pill.defaultProps = {
  color: 'none',
  filled: false,
  size: 'auto'
};

export default Pill;
export const {
  propTypes,
  defaultProps,
  displayName
} = Pill;
