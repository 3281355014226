import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Logo = (props) => {
  const { as: Component, className, children, ...rest } = props;
  const logoClasses = classnames(
    'particles-navigation-menu',
    'particles-navigation-menu--logo',
    className
  );
  return (
    <Component className={logoClasses} {...rest}>
      <div className="particles-navigation-logo" />
      <div className="particles-navigation-logo__brand">
        {children}
      </div>
    </Component>
  );
};
Logo.defaultProps = {
  as: 'a'
};
Logo.propTypes = {
  /** Child components */
  children: PropTypes.node,
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Logo.displayName = 'Navigation.Logo';

export default Logo;

export const {
  defaultProps,
  propTypes,
  displayName
} = Logo;
