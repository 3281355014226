import React from 'react';
import { context } from './context.js';
import _ from 'lodash';
import safeStringify from 'fast-safe-stringify';

const defaults = () => ({
  console: {
    warn: true,
    error: true,
    info: true,
    log: false,
    debug: false,
    trace: false,
    dir: false
  },
  componentMaturity: {
    level: {
      pre: 'fatal',
      deprecated: 'error',
      initial: 'error',
      unstable: 'warning',
      feedback: 'info',
      stable: 'none',
      proven: 'none'
    }
  }
});

const memoizedMergeResults = _.memoize(_.merge, (...args) => safeStringify(_.merge(...args)));

const ConfigurationProvider = ({ children, ...props }) => {
  if (!children) {
    return null;
  }
  const value = memoizedMergeResults(defaults(), props);
  return (
    <context.configuration.Provider value={value}>
      {children}
    </context.configuration.Provider>
  );
};

const useConfiguration = (props) => {
  const configurationFromProvider = React.useContext(context.configuration);
  return memoizedMergeResults(defaults(), configurationFromProvider, props);
};

export default { memoizedMergeResults, defaults, ConfigurationProvider, useConfiguration };

export { memoizedMergeResults, defaults, ConfigurationProvider, useConfiguration };
