import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const MainBody = ({ className, as, ...rest }) => {
  const classes = classnames('particles-page__main-body', className);
  const Component = as || 'div';
  return (
    <Component className={classes} {...rest} />
  );
};

MainBody.propTypes = {
  /** Child components */
  children: PropTypes.node,
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, Link, 'main', 'div' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};

MainBody.defaultProps = {
  className: ''
};

MainBody.displayName = 'Page.MainBody';

export default MainBody;

export const {
  propTypes,
  defaultProps,
  displayName
} = MainBody;
