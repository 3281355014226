import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Bar from '../Bar/index.js';

const Primary = (props) => {
  const { className, as: Component, ...rest } = props;
  const primaryClasses = classnames('particles-primary-navigation', className);
  const containerClasses = classnames('particles-primary-navigation__container');
  return (
    <Component className={primaryClasses} containerClassName={containerClasses} {...rest} />
  );
};

Primary.defaultProps = {
  as: Bar
};
Primary.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div', 'section', 'nav' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Primary.displayName = 'Navigation.Primary';

export default Primary;

export const {
  defaultProps,
  propTypes,
  displayName
} = Primary;
