import _ from 'lodash';

const eventHandlers = props => _.pick(props, [
  'onChange', 'onBlur', 'onFocus'
]);
const checkable = props => _.pick(props, [
  'checked', 'defaultChecked', 'standalone'
]);
const selectable = props => _.pick(props, [
  'autoFocus',
  'children'
]);
const textArea = props => _.pick(props, [
  'rows',
  'cols',
  'wrap',
  'maxLength',
  'children',
  'autoFocus',
  'placeholder'
]);
const text = props => _.pick(props, [
  'children',
  'autoFocus',
  'placeholder'
]);
const common = props => _.pick(props, [
  'className',
  'style',
  'name',
  'value',
  'defaultValue',
  'readOnly',
  'type',
  'disabled',
  'pattern',
  'required',
  'list'
]);

// https://redux-form.com/7.1.2/docs/api/field.md/#meta-props
const meta = props => _.pick(props, [
  'active',
  'autofilled',
  'asyncValidating',
  'dirty',
  'error',
  'initial',
  'invalid',
  'pristine',
  'submitting',
  'submitFailed',
  'touched',
  'valid',
  'visited',
  'warning',
  'placeholder'
]);

const exported = {
  eventHandlers,
  checkable,
  selectable,
  common,
  text,
  textArea,
  meta
};

export default exported;
export { eventHandlers, checkable, selectable, common, text, meta, textArea };
