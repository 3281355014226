import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';

const disabledProps = (disabled) => {
  if (!disabled) {
    return {};
  }
  return { disabled, onClick: e => _.invoke(e, 'preventDefault') };
};

const Link = (props) => {
  const { as: Component, active, primary, utility, footer, className, disabled, ...rest } = props;
  const linkClasses = classnames(
    'particles-navigation-link',
    className,
    {
      'particles-navigation-link--disabled': !!disabled,
      'particles-navigation-link--primary': !!primary,
      'particles-navigation-link--active': !!active,
      'particles-navigation-link--primary-active': !!active && !!primary,
      'particles-navigation-link--footer': !!footer,
      'particles-navigation-link--footer-active': !!active && !!footer,
      'particles-navigation-link--utility': !!utility,
      'particles-navigation-link--utility-active': !!active && !!utility
    }
  );
  return (
    <Component className={linkClasses} {...rest} {...disabledProps(disabled)} />
  );
};

Link.defaultProps = {
  footer: false,
  primary: false,
  utility: false,
  active: false,
  as: 'a'
};
Link.propTypes = {
  /** Disable Link, ignores clicks and uses disabled styling */
  disabled: PropTypes.bool,
  /** Enable primary styling */
  primary: PropTypes.bool,
  /** Enable utility styling */
  utility: PropTypes.bool,
  /** Enable footer styling */
  footer: PropTypes.bool,
  /** Enable active styling */
  active: PropTypes.bool,
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div', 'section', 'nav' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Link.displayName = 'Navigation.Link';

export default Link;

export const {
  defaultProps,
  propTypes,
  displayName
} = Link;
