import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Bar from '../Bar/index.js';

const Context = (props) => {
  const { className, as: Component, ...rest } = props;
  const contextClasses = classnames('particles-navigation-context', className);
  const containerClasses = classnames('particles-navigation-context__container');
  return (
    <Component className={contextClasses} containerClassName={containerClasses} {...rest} />
  );
};

Context.defaultProps = {
  as: Bar
};
Context.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div', 'section', 'nav' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Context.displayName = 'Navigation.Context';

export default Context;

export const {
  defaultProps,
  propTypes,
  displayName
} = Context;
