import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from '../PageContainer/index.js';

const MainHeader = ({
  className,
  title,
  category,
  action,
  children,
  withSections,
  ...rest
}) => {
  const classes = classnames('particles-page__main-header', {
    'particles-page__main-header--withSections': withSections
  }, className);
  const hasMetaInfo = !!title || !!category;
  return (
    <div className={classes} {...rest}>
      <Container noWrap>
        {hasMetaInfo && (
          <div className="particles-page__main-header-meta">
            {category && (
              <div className="particles-page__main-header-category">
                {category}
              </div>
            )}
            {title && (
              <h1 className="particles-page__main-header-title">{title}</h1>
            )}
          </div>
        )}
        {action && (
          <div className="particles-page__main-header-actions">{action}</div>
        )}
        {children && (
          <div className="particles-page__main-header-content">{children}</div>
        )}
      </Container>
    </div>
  );
};

MainHeader.propTypes = {
  /** Child components */
  children: PropTypes.node,
  /** Extra classes */
  className: PropTypes.string,
  /** Title to display */
  title: PropTypes.node,
  /** Category to display, appears above title with smaller size */
  category: PropTypes.node,
  /** A component to execute an action, ie: `Button`. */
  action:  PropTypes.node,
  /** Adds a line between multiple `Page.MainBodySection`. */
  withSections: PropTypes.bool
};

MainHeader.defaultProps = {
  className: '',
  withSections: false
};

MainHeader.displayName = 'Page.MainHeader';

export default MainHeader;

export const {
  propTypes,
  defaultProps,
  displayName
} = MainHeader;
