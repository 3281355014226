import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Downshift from 'downshift';
import Text from '../Text/index.js';
import Link from '../Link/index.js';

const Dropdown = props => {
  const {
    children,
    action,
    actionClass,
    dropdownClass,
    className,
    caret,
    utility,
    context,
    right,
    active,
    ...rest
  } = props;
  const classes = isOpen =>
    classnames(
      'particles-navigation-dropdown',
      {
        'particles-navigation-dropdown--utility': !!utility,
        'particles-navigation-dropdown--utility-active': !!utility && !!active,
        'particles-navigation-dropdown--context': !!context,
        'particles-navigation-dropdown--active': !!isOpen
      },
      className
    );
  const actionClasses = isOpen =>
    classnames(
      'particles-navigation-dropdown__action',
      {
        'particles-navigation-dropdown__action--utility': !!utility,
        'particles-navigation-dropdown__action--context': !!context,
        'particles-navigation-dropdown__action--active': !!isOpen
      },
      actionClass
    );
  const dropdownMenuClasses = classnames(
    'particles-navigation-dropdown__menu',
    {
      'particles-navigation-dropdown__menu--utility': !!utility,
      'particles-navigation-dropdown__menu--context': !!context,
      'particles-navigation-dropdown__menu--right': !!right
    },
    dropdownClass
  );
  const caretClasses = isOpen => classnames(
    'particles-navigation-dropdown__caret',
    {
      'particles-navigation-dropdown__caret--closed': !!isOpen,
      'particles-navigation-dropdown__caret--open': !!isOpen
    }
  );

  return (
    <Downshift {...rest}>
      {({
        getItemProps,
        getToggleButtonProps,
        isOpen
      }) => (<div className={classes(isOpen)}>
        <div tabIndex="0" className={actionClasses(isOpen)} {...getToggleButtonProps()}>
          {action} { caret && <i className={`fa fa-chevron-down ${caretClasses(isOpen)}`} aria-hidden="true" /> }
        </div>
        {isOpen ? (
          <div className={dropdownMenuClasses}>
            {React.Children.map(children, (child, i) => {
              if (!child || child.type === Text || !child.type) {
                return child;
              }
              if (child && child.type === Link) {
                return React.cloneElement(child, {
                  ...getItemProps({item: child})
                });
              }
              return React.cloneElement(child, { getItemProps });
            })}
          </div>
        ) : null}
      </div>
      )}
    </Downshift>
  );
};

Dropdown.Group = (props) => {
  const {
    as,
    className,
    children,
    getItemProps,
    ...rest
  } = props;
  const classes = () =>
    classnames(
      'particles-navigation-dropdown-group',
      className
    );
  const Component = as;
  return (
    <Component className={classes()} {...rest}>
      {React.Children.map(children, (child, i) => {
        if (!child || child.type === Text || !child.type) {
          return child;
        }
        if (child && child.type === Link) {
          return React.cloneElement(child, { ...getItemProps({ item: child }) });
        }
        return React.cloneElement(child, { getItemProps, ...rest });
      })}
    </Component>
  );
};
Dropdown.Item = (props) => {
  const {
    as,
    className,
    children,
    getItemProps,
    ...rest
  } = props;
  const classes = () =>
    classnames(
      'particles-navigation-dropdown-item',
      className
    );
  const Component = as;
  return (
    <Component className={classes()} {...rest}>
      {React.Children.map(children, (child, i) => {
        if (!child || child.type === Text || !child.type) {
          return child;
        }
        if (child && child.type === Link) {
          return React.cloneElement(child, {
            ...getItemProps({item: child})
          });
        }
        return React.cloneElement(child, { getItemProps, ...rest });
      })}
    </Component>
  );
};
Dropdown.Item.Label = (props) => {
  const {
    as,
    className,
    getItemProps,
    ...rest
  } = props;
  const classes = () =>
    classnames(
      'particles-navigation-dropdown-item__label',
      className
    );
  const Component = as;
  return (<Component className={classes()} {...rest} />);
};
Dropdown.Item.Value = (props) => {
  const {
    as,
    className,
    getItemProps,
    children,
    ...rest
  } = props;
  const classes = () =>
    classnames(
      'particles-navigation-dropdown-item__value',
      className
    );
  const Component = as;
  return (
    <Component className={classes()} {...rest}>
      {React.Children.map(children, (child, i) => {
        if (!child || child.type === Text || !child.type) {
          return child;
        }
        if (child && child.type === Link) {
          return React.cloneElement(child, {
            ...getItemProps({item: child})
          });
        }
        return React.cloneElement(child, { getItemProps, ...rest });
      })}
    </Component>
  );
};
Dropdown.Group.Header = (props) => {
  const {
    as,
    className,
    getItemProps,
    ...rest
  } = props;
  const classes = () =>
    classnames(
      'particles-navigation-dropdown-group__header',
      className
    );
  const Component = as;
  return (<Component className={classes()} {...rest} />);
};

Dropdown.Group.defaultProps = {
  className: '',
  as: 'div'
};
Dropdown.Group.propTypes = {
  /** String or function to transform the component into. */
  as: (...args) => PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType
  ])(...args)
};
Dropdown.Group.displayName = 'Dropdown.Group';

Dropdown.Group.Header.defaultProps = {
  className: '',
  as: 'span'
};
Dropdown.Group.Header.propTypes = {
  /** String or function to transform the component into. */
  as: (...args) => PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType
  ])(...args)
};
Dropdown.Group.Header.displayName = 'Dropdown.Group.Header';

Dropdown.Item.defaultProps = {
  className: '',
  as: 'div'
};
Dropdown.Item.propTypes = {
  /** String or function to transform the component into. */
  as: (...args) => PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType
  ])(...args)
};
Dropdown.Item.displayName = 'Dropdown.Item';

Dropdown.Item.Label.propTypes = {
  /** String or function to transform the component into. */
  as: (...args) => PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType
  ])(...args)
};
Dropdown.Item.Label.defaultProps = {
  className: '',
  as: 'span'
};
Dropdown.Item.Label.displayName = 'Dropdown.Item.Label';

Dropdown.Item.Value.propTypes = {
  /** String or function to transform the component into. */
  as: (...args) => PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType
  ])(...args)
};
Dropdown.Item.Value.defaultProps = {
  className: '',
  as: 'span'
};
Dropdown.Item.Value.displayName = 'Dropdown.Item.Value';


Dropdown.propTypes = {
  /** Dropdown Menu Content */
  children: PropTypes.node.isRequired,
  /** Dropdown text or component */
  action: PropTypes.node.isRequired,
  /** Enable caret content */
  caret: PropTypes.bool,
  /** Enable utility style */
  utility: PropTypes.bool,
  /** Enable context style */
  context: PropTypes.bool,
  /** Enable right alignment of dropdown menu */
  right: PropTypes.bool,
  /** Extra classes */
  className: PropTypes.string,
  /** Extra classes for dropdown content */
  dropdownClass: PropTypes.string,
  /** Extra classes for action content */
  actionClass: PropTypes.string,
  /** Downshift prop for converting item to a string value */
  itemToString: PropTypes.func
};

Dropdown.defaultProps = {
  caret: false,
  itemToString: i => i ? i.key || String(i) : ''
};

export default Dropdown;
