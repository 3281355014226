import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Page from '../../Page/index.js';

const Bar = (props) => {
  const { className, children, containerClassName, as: Component, ...rest } = props;
  const barClasses = classnames('particles-navigation-bar', className);
  const containerClasses = classnames('particles-navigation-bar__container', containerClassName);
  return (
    <Component className={barClasses} {...rest}>
      <Page.Container className={containerClasses}>
        {children}
      </Page.Container>
    </Component>
  );
};

Bar.defaultProps = {
  as: 'div'
};
Bar.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div', 'section', 'nav' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Bar.displayName = 'Navigation.Bar';

export default Bar;

export const {
  defaultProps,
  propTypes,
  displayName
} = Bar;
