import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

const whitelist = [
  'style',
  'autoFocus',
  'required',
  'placeholder',
  'type',
  'name',
  'disabled',
  'readOnly',
  'defaultValue',
  'value',
  'id',
  'onClick',
  'onBlur',
  'onFocus',
  'onChange',
  'pattern',
  'list'
];

const Text = (props = {}) => {
  const { className, type, label, name, help, error, children } = props;
  const inputClasses = classnames(
    'particles-input__input',
    'particles-input__input--meta-text',
    `particles-input__input--${type}`,
    `particles-input__input--${name}`,
    {
      'particles-input__input--with-controls': !!children,
      'particles-input__input--error': !!error
    }
  );
  const inputControlsWrapperClasses = classnames(
    'particles-input-and-controls'
  );
  const inputControlsClasses = classnames(
    'particles-input-and-controls__controls'
  );
  const wrapperClasses = classnames(
    'particles-input__wrapper',
    'particles-input__wrapper--meta-text',
    `particles-input__wrapper--${type}`,
    `particles-input__wrapper--${name}`,
    className
  );
  const input = (
    <input {..._.pick(props, whitelist)} className={inputClasses} />
  );
  const inputWithControls = children ? (
    <div className={inputControlsWrapperClasses}>
      {input}
      <div className={inputControlsClasses}>
        {children}
      </div>
    </div>
  ) : input;
  if (type === 'hidden') {
    return input;
  }
  return (
    <div className={wrapperClasses}>
      {label}
      {inputWithControls}
      {help}
      {error}
    </div>
  );
};

Text.displayName = 'Text';
Text.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** Type of element, set to 'text' */
  type: PropTypes.string,
  /** `name` attribute value */
  name: PropTypes.string,
  /** Label to display with text */
  label: PropTypes.node,
  /** Help message to display */
  help: PropTypes.node,
  /** Error message to display when there is an error */
  error: PropTypes.node,
  /** The value for the input */
  value: PropTypes.any,
  /** The list for the input */
  list: PropTypes.string
};

export default Text;

export const {
  displayName,
  propTypes
} = Text;
