import React from 'react';
import Grid from '../Grid/index.js';
import Collection from '../Collection/index.js';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Body from './body.js';
import Tile from './tile.js';
import Icon from './icon.js';
import Header from './header.js';
import context from './context.js';
import { useMaturityLevel } from '../../component-maturity.js';

const ChoiceTilesGrid = ({ children, ...gridProps }) => (
  <Grid withGutters className="justify-content-center" {...gridProps}>
    {_.map(children, (child, key) => (
      <Grid.Cell xs={12} sm={6} md={6} lg={4} verticalGutter key={key}>
        {child}
      </Grid.Cell>
    ))}
  </Grid>
);
ChoiceTilesGrid.displayName = 'ChoiceTilesGrid';

const ChoiceTilesCollection = (props) => (<Collection vertical {...props} />);

ChoiceTilesCollection.displayName = 'ChoiceTilesCollection';

const newValue = (multi) => multi ? (previous, value, checked) => (
  _.compact(_.uniq(checked ? _.concat(previous, [value]) : _.without(previous, value)))
) : (previous, value, checked) => (checked && (value || true));

export const ChoiceTiles = ({ as: Component, grid, children, onChange, multi, name, className, ...rest }) => {
  useMaturityLevel('feedback', 'ChoiceTiles');
  const [selected, setSelected] = React.useState({});
  const select = ({ target: { name, value, checked } } = { target: {} }) => {
    const newSelectedValue = _.merge({}, selected, {
      [name]: newValue(multi)(selected[name], value, checked)
    });
    if (onChange(newSelectedValue) !== false) {
      setSelected(newSelectedValue);
    }
  };
  return (
    <context.tiles.Provider value={{ select, multi, name }}>
      <Component className={`particles-choice-tiles ${className ? className : ''}`}>
        {grid ? (
          <ChoiceTilesGrid children={children} {...rest} />
        ) : (
          <ChoiceTilesCollection children={children} {...rest} />
        )}
      </Component>
    </context.tiles.Provider>
  );
};
ChoiceTiles.defaultProps = { as: 'div', onChange: _.noop };
ChoiceTiles.displayName = 'ChoiceTiles';
ChoiceTiles.propTypes = {
  /** Render as this kind of component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string,
  /** Input event handler */
  onChange: PropTypes.func,
  /** Organize tiles in a grid */
  grid: PropTypes.bool,
  /** Allows multi-selection */
  multi: PropTypes.bool,
  /** Determines the name of the input */
  name: PropTypes.string
};


ChoiceTiles.Tile = Tile;
ChoiceTiles.Icon = Icon;
ChoiceTiles.Body = Body;
ChoiceTiles.Header = Header;

export { Tile, Icon, Body, Header };
export default ChoiceTiles;
