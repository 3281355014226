import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Bar from '../Bar/index.js';

const Utility = (props) => {
  const { className, as: Component, ...rest } = props;
  const utilityClasses = classnames('particles-utility-navigation', className);
  const containerClasses = classnames('particles-utility-navigation__container');
  return (
    <Component className={utilityClasses} containerClassName={containerClasses} {...rest} />
  );
};

Utility.defaultProps = {
  as: Bar
};
Utility.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div', 'section', 'nav' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Utility.displayName = 'Navigation.Utility';

export default Utility;

export const {
  defaultProps,
  propTypes,
  displayName
} = Utility;
