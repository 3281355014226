import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Menu = (props) => {
  const { as: Component, primary, utility, context, className, right, ...rest } = props;
  const menuClasses = classnames(
    'particles-navigation-menu',
    className,
    {
      'particles-navigation-menu--context-right': !!context && !!right,
      'particles-navigation-menu--context-left': !!context && !right,
      'particles-navigation-menu--utility-right': !!utility && !!right,
      'particles-navigation-menu--utility-left': !!utility && !right,
      'particles-navigation-menu--primary': !!primary,
      'particles-navigation-menu--left': !right,
      'particles-navigation-menu--right': !!right
    }
  );
  return (
    <Component className={menuClasses} {...rest} />
  );
};

Menu.defaultProps = {
  as: 'div',
  primary: false,
  utility: false,
  right: false
};
Menu.propTypes = {
  /** Right justify menu */
  right: PropTypes.bool,
  /** Enable primary styling */
  primary: PropTypes.bool,
  /** Enable utility styling */
  utility: PropTypes.bool,
  /** Enable context styling */
  context: PropTypes.bool,
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div', 'section', 'nav' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Menu.displayName = 'Navigation.Menu';

export default Menu;

export const {
  defaultProps,
  propTypes,
  displayName
} = Menu;
