import React from 'react';
import classnames from 'classnames';
import Button from '../../Button/index.js';
import PropTypes from 'prop-types';
import _ from 'lodash';

const isDisabled = ({ loading, resolved, requiresChanges, requiresValid }) => !loading && (
  resolved.submitting ||
  (requiresChanges && !resolved.dirty) ||
  (requiresValid && !resolved.valid)
);

const Action = (props = {}, context = {}) => {
  const formState = context.formState || {};
  const {
    className,
    children,
    requiresChanges,
    requiresValid,
    as,
    type,
    valid,
    dirty,
    submitting,
    ...rest
  } = props;
  const resolved = {
    valid: valid !== undefined ? valid : formState.valid,
    dirty: dirty !== undefined ? dirty : formState.dirty,
    submitting: submitting !== undefined ? submitting : formState.submitting
  };
  const Component = as || Button;
  const actionClasses = classnames(
    'particles-form__action',
    className
  );
  const primary = (type === 'submit');
  const loading = (type === 'submit') && resolved.submitting;
  const disabled = isDisabled({ loading, resolved, requiresChanges, requiresValid });
  const componentProps = _.isString(Component) ? {} : {
    primary, loading
  };
  return (
    <Component
      className={actionClasses}
      disabled={disabled}
      type={type}
      {...componentProps}
      {...rest}
    >
      {children}
    </Component>
  );
};

Action.contextTypes = {
  formState: PropTypes.object
};
Action.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** Component type, ie: 'submit', 'button',... */
  type: PropTypes.string,
  /** Child components */
  children: PropTypes.node,
  /** Indicates that form needs to be changed to enable action. */
  requiresChanges: PropTypes.bool,
  /** Indicates that form needs to be validated to enable action. */
  requiresValid: PropTypes.bool,
  /** Whether form has been validated or not */
  valid: PropTypes.bool,
  /** Whether form has been changed or not */
  dirty: PropTypes.bool,
  /** Form is being submitted */
  submitting: PropTypes.bool,
  /** String or function to transform the component into, ie: Button, 'a', Link, etc... */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Action.displayName = 'Action';

export default Action;

export const {
  propTypes,
  displayName
} = Action;
