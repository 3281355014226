import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { useT } from '../../translations/index.js';
import { useMaturityLevel } from '../../component-maturity.js';

const Month = ({
  month,
  disabled,
  selected,
  hover,
  index,
  getItemProps,
  ...rest
}) => {
  const t = useT();
  const classes = classnames('particles-month-picker__month', {
    'particles-month-picker__month--disabled': !!disabled,
    'particles-month-picker__month--hover': !!hover,
    'particles-month-picker__month--selected': !!selected
  });
  return (
    <button {...getItemProps({ index, item: month })} className={classes} disabled={disabled}>
      {t('particlesShortMonthUtc', { date: moment.utc(month).toDate() })}
    </button>
  );
};

const MonthPickerItems = ({
  nextYear,
  previousYear,
  currentValue,
  visibleYear,
  highlightedIndex,
  min,
  max,
  ...rest
}) => {
  const t = useT();
  const classesDropdownContainer = classnames('particles-month-picker__dropdown');
  const classesCurrent = classnames('particles-month-picker__current-year');
  const classesYears = classnames('particles-month-picker__years');
  const classesMonths = classnames('particles-month-picker__months');
  const minYear = moment.utc(min).format('YYYY');
  const maxYear = moment.utc(max).format('YYYY');
  const currentYearValue = moment.utc(visibleYear).format('YYYY');
  const currentYear = t('particlesYearUtc', { date: moment.utc(visibleYear).toDate() });
  const classesNext = classnames('particles-month-picker__next-year', {
    'particles-month-picker__next-year--disabled': (currentYearValue >= maxYear)
  });
  const classesPrevious = classnames('particles-month-picker__previous-year', {
    'particles-month-picker__previous-year--disabled': (currentYearValue <= minYear)
  });
  return (
    <div className={classesDropdownContainer}>
      <div className={classesYears}>
        <button onClick={previousYear} className={classesPrevious}>
          <i className="fa fa-chevron-left" />
        </button>
        <span className={classesCurrent}>
          {currentYear}
        </span>
        <button onClick={nextYear} className={classesNext}>
          <i className="fa fa-chevron-right" />
        </button>
      </div>
      <div className={classesMonths}>
        {_.map(_.range(0, 12), (monthZeroBaseIndex) => {
          const minMonth = moment.utc(min).format('YYYY-MM');
          const maxMonth = moment.utc(max).format('YYYY-MM');
          const month = moment.utc(visibleYear).month(monthZeroBaseIndex);
          const monthFormat = month.format('YYYY-MM');
          const disabled = (monthFormat < minMonth) || (monthFormat > maxMonth);
          const hover = monthZeroBaseIndex === highlightedIndex;
          const selected = moment.utc(currentValue).format('YYYY-MM') === monthFormat;
          return (
            <Month
              month={month.format()}
              key={monthZeroBaseIndex}
              disabled={disabled}
              selected={selected}
              hover={hover}
              index={monthZeroBaseIndex}
              {...rest}
            />
          );
        })}
      </div>
    </div>
  );
};

/**  Select a Month and Year */
const MonthPicker = ({ startOpen, onChange, initialValue, className, ...props }) => {
  useMaturityLevel('stable', 'MonthPicker');
  const t = useT();
  const [visibleYear, setVisibleYear] = React.useState(initialValue);
  const guardedOnChange = newValue => {
    const value = newValue || initialValue;
    onChange(moment.utc(value).format('YYYY-MM'));
  };
  const defaultValue = moment.utc(initialValue).format();
  const onStateChange = changes => _.get(changes, 'isOpen') && setVisibleYear(initialValue);
  const addToYear = delta => {
    const min = moment.utc(props.min).format('YYYY');
    const max = moment.utc(props.max).format('YYYY');
    const target = moment.utc(visibleYear).add(delta, 'years');
    const targetYear = target.format('YYYY');
    if ((targetYear < min) || (targetYear > max)) {
      return;
    }
    setVisibleYear(target.format());
  };
  const nextYear = () => addToYear(1);
  const previousYear = () => addToYear(-1);
  return (
    <Downshift onChange={guardedOnChange} defaultSelectedItem={defaultValue} defaultIsOpen={startOpen} onStateChange={onStateChange}>
      {({ selectedItem, getToggleButtonProps, isOpen, ...downshift }) => {
        const classes = classnames('particles-month-picker', className, {
          'particles-month-picker--open': !!isOpen,
          'particles-month-picker--closed': !isOpen
        });
        const classesTrigger = classnames('particles-month-picker__trigger', {
          'particles-month-picker__trigger--open': !!isOpen,
          'particles-month-picker__trigger--closed': !isOpen
        });
        const currentValue = selectedItem || initialValue;
        return (
          <div className={classes}>
            <button {...getToggleButtonProps()} className={classesTrigger}>
              {t('particlesLongMonthYearUtc', { date: moment.utc(currentValue).toDate() })}{' '}
              {isOpen ? <i className="fa fa-caret-up" /> : <i className="fa fa-caret-down" />}
            </button>
            {isOpen && (
              <MonthPickerItems {...downshift} {...props}
                nextYear={nextYear}
                previousYear={previousYear}
                currentValue={currentValue}
                visibleYear={visibleYear} />
            )}
          </div>
        );
      }}
    </Downshift>
  );
};

MonthPicker.propTypes = {
  /** Deprecated */
  forceOpen: PropTypes.bool,
  /** Display open on initial render */
  startOpen: PropTypes.bool,
  /** Starting date value, uses moment.utc internally for parsing */
  initialValue: PropTypes.any,
  /** Minimum month/year, uses moment.utc internally for parsing */
  min: PropTypes.any,
  /** Maximum month/year, uses moment.utc internally for parsing*/
  max: PropTypes.any,
  /** Custom handler for the onChange event */
  onChange: PropTypes.func
};

MonthPicker.defaultProps = {
  startOpen: false,
  initialValue: moment.utc().format(),
  min: moment.utc().add(-1, 'years').format(),
  max: moment.utc().format(),
  onChange: _.noop
};

export default MonthPicker;

export const {
  propTypes,
  defaultProps
} = MonthPicker;
